// Compatible with @progress/kendo-theme-material v.6.0.3


$tb-kendo-border-radius: 4px;
$tb-primary-palette-name: indigo;
$tb-adjust-contrast: true;
$tb-secondary-palette-name: green;
$tb-theme-type: light;
$tb-kendo-body-bg: #ffffff;
$tb-kendo-series-a: #373435;
$tb-kendo-series-b: #2196f3;
$tb-kendo-series-c: #4f874a;
$tb-kendo-series-d: #a2add8;
$tb-kendo-series-e: #f44336;
$tb-kendo-series-f: #4f874a;

$kendo-border-radius: $tb-kendo-border-radius;
$primary-palette-name: $tb-primary-palette-name;
$adjust-contrast: $tb-adjust-contrast;
$secondary-palette-name: $tb-secondary-palette-name;
$theme-type: $tb-theme-type;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-series-a: $tb-kendo-series-a;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
    $kendo-font-family: Roboto, "Helvetica Neue", sans-serif;
    $kendo-font-size: 14px;
    $kendo-font-weight-normal: 400;
    $kendo-line-height: 2;
    
$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
      font-family: 'Roboto, "Helvetica Neue", sans-serif',
      font-size: 14px,
      font-weight: 400,
      line-height: 2,
  ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }
  }
}